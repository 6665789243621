import { SyntheticEvent, useState, FC } from 'react';
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  TextField,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { Calendar } from '@mui/x-date-pickers/internals/components/icons';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider } from 'src/common/components/hook-form';
import Iconify from 'src/common/components/Iconify';
import { FORMAT_DATE_NEWS } from 'src/common/constants/common.constants';

import { CoinStatusList } from 'src/coin/common/constant';
import useTable from 'src/common/hooks/useTable';

export const initialFilter = {
  fromDate: '',
  toDate: '',
  deliveryCode: '',
  userId: '',
  tdId: '',
};

const CoinListFilter: FC<{ handleSearch: Function }> = ({ handleSearch }) => {
  const propsTable = useTable();

  const methods = useForm({
    defaultValues: initialFilter,
  });

  const onSubmit = (data: any) => {
    const fromDate = data.fromDate
      ? new Date(
          Date.UTC(
            data.fromDate.getFullYear(),
            data.fromDate.getMonth(),
            data.fromDate.getDate(),
            data.fromDate.getHours(), // Preserve hours
            data.fromDate.getMinutes(), // Preserve minutes
            data.fromDate.getSeconds() // Preserve seconds
          )
        ).toISOString()
      : null;

    const toDate = data.toDate
      ? new Date(
          Date.UTC(
            data.fromDate.getFullYear(),
            data.fromDate.getMonth(),
            data.fromDate.getDate(),
            data.fromDate.getHours(), // Preserve hours
            data.fromDate.getMinutes(), // Preserve minutes
            data.fromDate.getSeconds() // Preserve seconds
          )
        ).toISOString()
      : null;

    const params = {
      fromDate: fromDate,
      toDate: toDate,
      deliveryCode: data.deliveryCode,
      userId: data.userId,
      tdId: data.tdId,
      page: propsTable.page,
      size: propsTable.rowsPerPage,
    };

    handleSearch(params);
  };

  const handleResetSearch = () => {
    reset({ ...initialFilter });
    handleSearch({
      page: propsTable.page,
      size: propsTable.rowsPerPage,
      fromDate: '',
      toDate: '',
      deliveryCode: '',
      userId: '',
      tdId: '',
    });
  };

  const { control, handleSubmit, reset } = methods;

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ px: 2 }}>
          <Grid container spacing={3} py="20px">
            <Grid item xs={2} sx={{ marginBottom: '15px' }}>
              <Controller
                name="deliveryCode"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    placeholder={'Search by deliveryCode'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={'eva:search-fill'}
                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={2} sx={{ marginBottom: '15px' }}>
              <Controller
                name="userId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    placeholder={'Search by userId'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={'eva:search-fill'}
                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={2} sx={{ marginBottom: '15px' }}>
              <Controller
                name="tdId"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    placeholder={'Search by tdId'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon={'eva:search-fill'}
                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={2}>
              <Stack spacing={'10px'}>
                <Controller
                  name="fromDate"
                  key="fromDate"
                  control={control}
                  render={({ field }) => (
                    <MobileDateTimePicker
                      {...field}
                      label="Từ ngày"
                      key="fromDate"
                      inputFormat={FORMAT_DATE_NEWS}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar />
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={2}>
              <Stack spacing={'10px'}>
                <Controller
                  name="toDate"
                  key="toDate"
                  control={control}
                  render={({ field }) => (
                    <MobileDateTimePicker
                      {...field}
                      label="Đến ngày"
                      key="toDate"
                      inputFormat={FORMAT_DATE_NEWS}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar />
                          </InputAdornment>
                        ),
                      }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Stack
              direction="row"
              spacing={3}
              marginTop="10px"
              mx={2}
              alignItems="center"
            >
              <LoadingButton
                type="submit"
                variant="contained"
                size="small"
                sx={{ height: '40px', padding: '4px 12px' }}
              >
                Tìm kiếm
              </LoadingButton>
              <Button
                type="reset"
                variant="contained"
                color="inherit"
                size="small"
                onClick={handleResetSearch}
                sx={{ height: '40px', padding: '4px 12px' }}
              >
                Xóa
              </Button>
            </Stack>
          </Grid>
        </Card>
      </FormProvider>
    </>
  );
};

export default CoinListFilter;
